import Vue from 'vue'
import $x from '../libs/$x'
import router from '../router/routerMain.js'
import main from '../views/app.vue'
import ElementUI from 'element-ui';
// import {
//   initThemeColor
// } from '../libs/themeColorClient'
import 'view-design/dist/styles/iview.css';
import store from '../store/index.js'
import vueWaves from '@/directive/waves/index' // 水波纹指令
import vueImgAlart from '@/directive/imgAlart/index' // 图片弹窗指令
import platform from '../libs/utils/platform.js'
import frequence from '../libs/utils/frequence.js'
import VueClipboard from 'vue-clipboard2'
import VueQuillEditor from 'vue-quill-editor'
import pictureDialog from '@/component/pc/pictureDialog';
import videoDialog from '@/component/pc/videoDialog';
import { Swipe, SwipeItem } from '@/component/common/vueSwiper';
import Print from 'vue-print-nb'

import VueI18n from 'vue-i18n'
import zh from '@/i18n/langs/zh'
import en from '@/i18n/langs/en'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { VueJsonp } from 'vue-jsonp'

import EleEn from '../../node_modules/element-ui/lib/locale/lang/en.js'
import EleZh from '../../node_modules/element-ui/lib/locale/lang/zh-CN.js'

require('../css/index.scss');
require('@/component/common/vueSwiper/vue-swipe.css');

const lang = localStorage.getItem('lang') || 'zh'
console.log('🍉','lang',lang);

// 通用组件，便于处理
Vue.prototype.$x = Vue.$x = $x;
VueClipboard.config.autoSetContainer = true;

Vue.use(VueJsonp)
Vue.use(vueWaves)
Vue.use(vueImgAlart)
Vue.use(ElementUI,{locale: lang === 'zh' ? EleZh : EleEn})
Vue.use(VueClipboard);
Vue.use(VueQuillEditor, { placeholder: '请输入内容' })
Vue.use(Print);
Vue.component('pictureDialog', pictureDialog);
Vue.component('videoDialog', videoDialog);
Vue.component('swipe', Swipe);
Vue.component('swipe-item', SwipeItem);

let currentDevice = ''
function getCurrentDeviceName() {
  if (platform.isPcPlatform()) {
    return 'pc'
  } else if (platform.isMobilePlatform()) {
    return 'mobile'
  } else if (platform.isTabletPlatform()) {
    return 'tablet'
  }
  return ''
}
currentDevice = getCurrentDeviceName()
function setRounder(e) {
  if (getCurrentDeviceName() !== currentDevice) {
    location.reload();
  }
}
window.addEventListener('resize', frequence.debounce(1, setRounder));

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: lang, // 设置默认语言
  messages: {
    'zh': zh,
    'en': en
  },
  silentTranslationWarn: true
});

Vue.prototype.$t = function(key, values){
  return i18n.t(key, values)
}
window.$t = Vue.prototype.$t

// initThemeColor()
export default new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(main)
});

/* IFDEBUG
window.$x = $x
IFDEBUG */
