
import $x from "@/libs/$x.js";
export default {
  data() {
    return {};
  },
  created() {
    const shopId = $x.localStorage.getItem("SHOP_ID");
    if (shopId) {
      this.$store.state.user.shopId = shopId;
    }

    console.log("env-", process.env.ENV_CONFIG);
    console.log("platform-", process.env.PLATFORM_TYPE);

    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      const width = window.innerWidth;
      this.$store.dispatch('common/screenSizeChange', width);
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
